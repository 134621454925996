<template>
  <v-container class="view" :class="[$vuetify.breakpoint.mobile ? '' : 'desktop']">
    <v-row>
      <v-col class="col-10 col-sm-10 col-lg-12" style="margin:auto">
        <Contents
          :header="header"
          :title="title"
          :subTitle="subTitle"
          :contents="contents" />
        <v-container style="padding: 8%;">
          <Mail :name="email" />
          <iframe class="Image-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12712.001690131656!2d127.0977647!3d37.2002194!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357b450077e9053d%3A0x3b2c8b2ca794a00f!2zS0FJU1Qg7ZmU7ISx7IucIOyCrOydtOyWuOyKpO2XiOu4jA!5e0!3m2!1sko!2skr!4v1705535570717!5m2!1sko!2skr" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Contents from '../components/Contents.vue';
import Mail from '../components/Mail.vue';

export default{
  name: 'Contact',
  components:{
    Contents,
    Mail,
  },
  data() {
    return {
      header: "Contact Us",
      title: "",
      subTitle: "",
      contents: [
      ],
      email: "Welcome questions about ITDA’s Features or solutions :",
    }
  }
}
</script>

<style scoped>
.mail{
  display:flex;
  flex-direction: row;
}
.Image-map {
  width: 100%;
  height: 500px;
  /* margin: 0 0 160px 0; */
  background-color: white;
}
</style>
  

<template>
    <v-container>
        <v-row>
            <v-col class="col-12 col-sm-12" :class="[split ? 'col-lg-5' : 'col-lg-12']">
                <v-row style="height: 80px;"></v-row>
                <v-row :class="[$vuetify.breakpoint.mobile ? '' : 'desktop']">
                    <ContentsHeader :name="header" />
                    <ContentsTitle :name="title" />
                    <ContentsSubTitle :name="subTitle" />
                    <v-col
                        v-for="(name, idx) in contents"
                        :key="idx"
                        cols="12"
                    >
                        <span class="Desc" v-html="name"></span>
                    </v-col>
                </v-row>
            </v-col>
            <v-col
                v-if="split"
                cols="1"
            >
            </v-col>
            <v-col
                v-if="image"
                class="col-12 col-sm-12 col-lg-6" :class="[split ? 'col-lg-6' : 'col-lg-12']"
            >
                <img :src="image.src" :srcset="image.srcset" :class="[$vuetify.breakpoint.mobile ? '' : 'desktop-img']">
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import ContentsHeader from './ContentsHeader.vue'
import ContentsTitle from './ContentsTitle.vue'
import ContentsSubTitle from './ContentsSubTitle.vue'

export default {
    name: 'ContentsMain',
    components: {
        ContentsHeader,
        ContentsTitle,
        ContentsSubTitle
    },
    props: {
        split: Boolean,
        header: String,
        title: String,
        subTitle: String,
        contents: Array,
        image: Object
    },
    data () {
        return {}
    },
    computed: {
    },
    methods: {
    }
}
</script>
  
<style scoped>
.desktop {
    padding: 3% 3% 3% 8%;
}
.Desc {
    font-family: 'Pretendard Variable';
    font-size: 1.25em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #fff;
}
img {
  width: 100%;
  /* padding: 8%; */
  &.desktop-img {
    padding: 8%;
  }
  object-fit: contain;
  mix-blend-mode: difference;
}
.highlight {
    color: #70eeff;
}
</style>
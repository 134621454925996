<template>
  <v-container class="view" :class="[$vuetify.breakpoint.mobile ? '' : 'desktop']">
    <v-row>
      <v-col class="col-10 col-sm-10 col-lg-12" style="margin:auto">
        <Contents
          :header="header"
          :title="title"
          :subTitle="subTitle"
          :contents="contents" />
        <Vision/>
        <!-- <CoreValues/> -->

        <v-container>
          <v-col cols="12" style="display:flex; justify-content: center;">
            <img src="@/assets/core-values.png" :class="[$vuetify.breakpoint.mobile ? 'mobile' : '']">
          </v-col>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Contents from '../components/Contents.vue';
import Vision from '../components/images/about/Vision.vue';
// import CoreValues from '../components/images/about/CoreValues.vue';
export default{
  name: 'About',
  components:{
    Contents,
    Vision,
    // CoreValues,
  },
  data() {
    return {
      header: "About ITDA",
      title: "No-Code SoC Design",
      subTitle: "Easiest And Fastest Way To Optimize Your SOC System Design",
      contents: [
        `SoC design can be challenging in terms of communication and system optimization.<br>However, with ITDA's SoC Canvas, optimizing the entire SoC system becomes <span style="color: #70eeff;">more intuitive and easy </span>to manage.`
      ]
    }
  }
}
</script>

<style scoped>
img {
  width: 50vw;
  padding: 5%;
  &.mobile {
    width: 100%;
    padding: 0;
  }
}
</style>
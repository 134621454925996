<template>
  <div
    class="PC_main_assets"
  >
    <Rectangle />
    <Main id="main" />

    <Rectangle />
    <Features id="features" type="power" />

    <Rectangle />
    <Features type="money" />

    <Rectangle />
    <About id="about" />

    <!--<Rectangle />
    <Careers id="careers" />-->

    <Rectangle />
    <Contact id="contact" />

    <Copyright />
  </div>
</template>

<script>
import Rectangle from '../components/Rectangle.vue';
import Main from './Main.vue';
import Features from './Features.vue';
import About from './About.vue';
//import Careers from './Careers.vue'
import Contact from './Contact.vue'
import Copyright from './Copyright.vue'
export default {
  name: 'Home',
  components: {
    Rectangle,
    Main,
    Features,
    About,
    //Careers,
    Contact,
    Copyright
  },
  data() {
    return {
    }
  },
  computed: {
    style() {
      return {
        width: this.$vuetify.breakpoint.width,
        height: this.$vuetify.breakpoint.height
      }
    }
  },
}
</script>

<style>
.PC_main_assets {
  background-color: #071c3b;
}
</style>

<template>
    <v-row>
        <v-col cols="12" style="padding: 10%;">
            <v-row :class="[$vuetify.breakpoint.mobile ? 'mobile' : '']">
                <v-col cols="12"  class="InfoTable">
                    <img src=   "@/assets/bar.png"
                        srcset="@/assets/bar@2x.png 2x, 
                                @/assets/bar@3x.png 3x"
                        class="bar"
                        :class="[$vuetify.breakpoint.mobile ? 'mobile-bar' : '']"
                    >
                    <InfoTableTitle :name="title" />
                    <InfoTableContents :names="contents" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import InfoTableTitle from './InfoTableTitle.vue'
import InfoTableContents from './InfoTableContents.vue'

export default {
    components: {
        InfoTableTitle,
        InfoTableContents
    },
    props: {
        title: String,
        contents: Array
    },
    data () {
        return {}
    },
    methods: {}
}
</script>

<style>
.mobile {
    padding: 3%;
    .InfoTable {
        border: solid 3px #70eeff;
    }
}
.InfoTable {
    border: solid 6px #70eeff;
    padding: 3% !important;
}
img.bar {
    display: flex;
    flex-direction: row;
    width: 20px;
    height: 20px;
    margin: 1% 0 0 98%;
    &.mobile-bar {
        width: 10px;
        height: 10px;
        margin: 0.5% 0 0 97%;
    }
    object-fit: contain;
    position: relative;
}
</style>
 



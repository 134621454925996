<template>
    <v-row>
        <v-col cols="12" style="padding: 0 3%;">
            <span class="Title" v-html="name"></span>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        name: String
    }
}
</script>

<style scoped>
.Title{
    display: flex;
    flex-direction: row;
    height: 31px;
    font-family: Pretendard;
    font-size: 1.5625em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #70eeff;
    position: relative;
}
</style>
<template>
    <v-container>
        <span class="Welcome">{{ name }}</span>
        <div class="mail">
            <img src="@/assets/icon-mail.png"
                srcset="@/assets/icon-mail@2x.png 2x,
                @/assets/icon-mail@3x.png 3x"
                class="icon-mail">
            <span class="contactitdasemicom"> {{email}} </span>
        </div>
    </v-container>
</template>

<script>
export default {
    props: {
        name: String
    },
    data() {
        return {
            email: "contact@itdasemi.com"
        }
    }
}
</script>

<style>
.Welcome {
    /* width: 640px; */
    height: 29px;
    margin: 0;
    font-family: Pretendard;
    font-size: 1.5em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}
.mail{
    display:flex;
    flex-direction: row;
}
.contactitdasemicom {
    /* width: 296px; */
    height: 20px;
    margin-top: 24px;
    margin-bottom: 20px;
    font-family: Pretendard;
    font-size: 1.125em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    color: #fff;
}
img.icon-mail {
    width: 20px;
    height: 20px;
    margin: 24px 8px 0 0;
    object-fit: contain;
}
</style>
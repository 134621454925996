<template>
    <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="10">
            <span class="Title" v-html="name" :style="style"></span>
        </v-col>
        <v-col cols="1"></v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        name: String
    },
    computed: {
        style() {
            return {
                fontSize: this.$vuetify.breakpoint.mobile ? "1.2em" : "1.5em"
            }
        }
    }
}
</script>

<style scoped>
.Title{
    /* width: 620px; */
    height: 29px;
    margin: 0 ;
    font-family: Pretendard;
    font-size: 1.5em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    display: inline-block;
}
</style>
<template>
    <div class="Rectangle">
    </div>
</template>
  
<script>
export default {
    name: 'Rectangle'
}
</script>
  
<style scoped>
.Rectangle {
    position: absolute;
    width: 100%;
    height: 300px;
    object-fit: contain;
    mix-blend-mode: color;
    background-image: linear-gradient(to bottom, #000, #071c3b);
}
</style>
<template>
    <div>
        <v-app-bar
            app
            color="linear-gradient(to bottom, #000, #071c3b)"
            dark
            right
        >
            <v-row v-resize="onResize">
                <v-col cols="1"></v-col>
                <v-col cols="1" style="display: flex; align-items: center;">
                    <a href="#main" class="view">
                        <img src="@/assets/logo-pc-white.png"
                            srcset="@/assets/logo-pc-white@2x.png 2x, @/assets/logo-pc-white@3x.png 3x">
                    </a>
                </v-col>
                <v-col cols="9" style="display: flex; align-items: center;">
                    <div v-if="!$vuetify.breakpoint.mobile" class="nav nav-right">
                        <v-row  justify="center">
                            <v-col
                                v-for="(obj, idx) in menus"
                                :key="idx"
                                class="col-12 col-sm-3 col-lg-3 justify-center"
                                style="display:flex;"
                            >
                                <a :href="`#${obj.name}`"> {{ obj.label }} </a>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-else class="nav-right">
                        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
                    </div>
                </v-col>
                <v-col cols="1"></v-col>
            </v-row>
        </v-app-bar>
  
        <v-navigation-drawer
            v-model="drawer"
            absolute
            color="#071c3b"
            temporary
            dark
            right
            width="100%"
            style="position:fixed;"
        >
            <v-toolbar
                dark
            >
                <img src="@/assets/logo-pc-white.png"
                    srcset="@/assets/logo-pc-white@2x.png 2x, @/assets/logo-pc-white@3x.png 3x">
                <v-spacer></v-spacer>
                <v-btn icon @click="close">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-list
                nav
                dense
                two-line
            >
            <v-list-item-group
                v-model="group"
                active-class="deep-purple--text text--accent-4"
            >
                <v-list-item
                    v-for="(obj, idx) in menus"
                    :key="idx"
                    color="#70eeff !important"
                    @click="click(obj.name)"
                >
                    <v-list-item-title style="fontSize: 1rem;">{{obj.label}}</v-list-item-title>
                </v-list-item>
            </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Header',
  
    components: {
    },
  
    computed: {
    //   style() {
    //     return {
    //       width: `${this.windowSize.width}vw`,
    //       height: `${this.windowSize.height}vw`
    //     }
    //   }
    },
  
    watch: {
      group() {
        this.drawer = false
      }
    },
  
    methods: {
      onResize () {
        // this.windowSize = { width: window.innerWidth, height: window.innerHeight }
        // this.windowSize = { width: window.outerWidth, height: window.outerHeight }
        // this.isMobile = (window.innerWidth <= 768) ? true : false
      },
      close() {
        this.drawer = false
      },
      click(e) {
        window.location.href = `#${e}`
      }
    },
  
    data: () => ({
      drawer: false,
      group: null,
      windowSize: {
        width: 0,
        height: 0
      },
      menus: [
        {
          name: "features",
          label: 'Features'
        },
        {
          name: "about",
          label: "About ITDA"
        },
        /*{
          name: "careers",
          label: "Careers"
        },*/
        {
          name: "contact",
          label: "Contact Us"
        }
      ]
    }),
  
    mounted() {
      console.log(process.env)
      this.onResize()
    }
  };
  </script>
  
<style>
.nav {
    width: 600px;
    float: right;
}
.nav-right {
    margin-left: auto;
}
.menu {
    display: flex;
    flex-direction: row;
}
a {
    font-family: Pretendard;
    font-size: 1.1875em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: white !important;
    text-decoration-line: none;
}
</style>

<template>
    <v-row>
        <v-col cols="12" style="height: 50px;"></v-col>
        <v-col cols="12" style="padding: 0 3%;">
            <v-row
                v-for="(name, idx) in names"
                :key="idx"
            >
                <v-col class="col-10 col-sm-10 col-lg-8">
                    <span class="Desc" v-html="name"></span>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12"></v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        names: Array
    },
}
</script>

<style scoped>
.Desc {
    /* display: flex; */
    /* flex-direction: row; */
    /* width: 620px; */
    /* height: 56px; */
    /* margin: 0 0 5% 5%; */
    font-family: Pretendard;
    font-size: 1.25em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #fff;
    position: relative;
}
</style>
<template>
  <v-app>
    <Header></Header>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Header from './views/Header.vue'
export default {
  name: 'App',

  components: {
    Header
  },

  data: () => ({
  }),

  // metaInfo: {
  //   title: 'ITDA',
  //   titleTemplate: '%s | 잇다반도체',
  //   meta: [
  //     { vmid: 'description',  name: 'description',  content: 'No-Code Power And Clock System Design.' },
  //     { vmid: 'keywords',     name: 'keywords',     content: 'itda,itdasemi,잇다반도체' },
  //     // { property: 'og:title',       content: 'IDTA | 잇다반도체' },
  //     // { property: 'og:description', content: 'No-Code Power And Clock System Design.' },
  //     // // { property: 'og:image', content: 'https://www.itdasemi.com/favicon.ico' },
  //     // { property: 'og:url',         content: 'https://www.itdasemi.com' },
  //     // { property: 'og:type',        content: 'website' },
  //   ]
  // },

  mounted() {
    // document.onkeydown = (e) => {
    //   if (e.keyCode == 123) {
    //     return false
    //   }
    // }
    // document.oncontextmenu = () => {
    //   return false
    // }
  }
};
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
body {
  overflow: hidden;
  
}
span{
  /* font-family: 'Open Sans', sans-serif !important; */
  font-family: 'Roboto', sans-serif !important;
}
@media only screen and (max-width:800px){
    body{
      font-size: 60%;
      /* zoom:0.5; */
    }
}
.view {
  z-index: 1;
  position: relative;

  &.desktop {
    padding: 8%;
  }
}
</style>
<template>
  <v-container class="view" :class="[$vuetify.breakpoint.mobile ? '' : 'desktop']">
    <v-row v-if="type==='power'">
      <v-col class="col-10 col-sm-10 col-lg-12" style="margin:auto">
        <Contents
          :title="power.title"
          :subTitle="power.subTitle"
          :contents="power.contents"
          :image="power.image" />
        <!-- <v-row style="height: 100px;"></v-row> -->

        <!-- <PowerManageTable type = "soft" /> -->
        <!-- <PowerManageTable type = "hard" /> -->
        <v-container style="display:flex; justify-content: center;">
          <v-row>
            <v-col cols="12" style="display:flex; justify-content: center;" >
              <img src="@/assets/power_manage_table_soft.png" :class="[$vuetify.breakpoint.mobile ? 'mobile' : '']">
            </v-col>
            <v-col cols="12" style="display:flex; justify-content: center;">
              <img src="@/assets/power_manage_table_hard.png" :class="[$vuetify.breakpoint.mobile ? 'mobile' : '']">
            </v-col>
          </v-row>
        </v-container>

        <InfoTable :title="power.info.title" :contents="power.info.contents" />
        <!-- <v-row style="height: 100px;"></v-row> -->
        <!-- <Frame /> -->
        <v-container>
          <v-row>
            <v-col cols="12" style="display:flex; justify-content: center;">
              <img src="@/assets/frame.png" :class="[$vuetify.breakpoint.mobile ? 'mobile' : '']">
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="col-10 col-sm-10 col-lg-12" style="margin:auto">
        <Contents
          :split="false"
          :title="money.title"
          :subTitle="money.subTitle"
          :contents="money.contents"
          :image="money.image" />
        <!-- <v-row style="height: 100px;"></v-row> -->

        <!-- <Conventional/> -->
        <!-- <DesignProcess/> -->
        <v-container>
          <v-row>
            <v-col cols="12" style="display:flex; justify-content: center;">
              <img src="@/assets/conventional.png" :class="[$vuetify.breakpoint.mobile ? 'mobile' : '']">
            </v-col>
            <v-col cols="12" style="display:flex; justify-content: center;">
              <img src="@/assets/design-process.png" :class="[$vuetify.breakpoint.mobile ? 'mobile' : '']">
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Contents from '../components/Contents.vue'
import InfoTable from '../components/InfoTable.vue';

// import PowerManageTable from '../components/images/features/power/PowerManageTable.vue'
// import Frame from '../components/images/features/power/Frame.vue';
// import Conventional from '../components/images/features/money/Conventional.vue';
// import DesignProcess from '../components/images/features/money/DesignProcess.vue';

import savepowerImg1x from '@/assets/savepower-img.png'
import savepowerImg2x from '@/assets/savepower-img@2x.png'
import savepowerImg3x from '@/assets/savepower-img@3x.png'

import savemoneyImg1x from '@/assets/savemoney-img.png'
import savemoneyImg2x from '@/assets/savemoney-img@2x.png'
import savemoneyImg3x from '@/assets/savemoney-img@3x.png'

export default {
  components: {
    Contents,
    InfoTable,
    // Frame,
    // Conventional,
    // PowerManageTable,
    // DesignProcess
  },
  props: {
    type: String
  },
  data() {
    return {
      power: {
        title: "Save Power",
        subTitle: `Hardware Based Power Management Can Further Reduce${this.$vuetify.breakpoint.mobile ? " " : "<br>"}Soc Power Consumption`,
        contents: [
          `Hardware dedicated power management can achieve a remarkable reduction${this.$vuetify.breakpoint.mobile ? " " : "<br>"}in IDLE power consumption, up to 95%.`,
          `Additionally, it covers 2.5 times more areas and provides a response time that is at least${this.$vuetify.breakpoint.mobile ? " " : "<br>"}1000 times faster compared to other methods.`
        ],
        image: {
          src: savepowerImg1x,
          srcset: `${savepowerImg2x} 2x, ${savepowerImg3x} 3x`
        },
        info: {
          title: `Hardware Based Power Management Finds Chances Of Sleep`,
          contents: [
            `Immediately following the completion of an IP's operation,${this.$vuetify.breakpoint.mobile ? " " : "<br>"}dedicated hardware controllers can transition any of the IPs into sleep modes.`
          ]
        }
      },
      money: {
        title: "Save Money",
        subTitle: "Power/Clock Canvas Will Remove Your Hidden Costs",
        contents: [
          `SoC power and clock design typically involve numerous documents and engineers,${this.$vuetify.breakpoint.mobile ? " " : "<br>"}leading to potential communication gaps and iterations.`,
          `Although these challenges may be challenging to quantify, they do incur costs.`,
          `The Power and Clock Canvas, by encompassing all system information and maintaining${this.$vuetify.breakpoint.mobile ? " " : "<br>"}data consistency, helps mitigate these issues.`,
          `Utilizing the power and clock canvas can result in savings by addressing hidden costs${this.$vuetify.breakpoint.mobile ? " " : "<br>"}associated with communication challenges and iterations in the design process.`
        ],
        image: {
          src: savemoneyImg1x,
          srcset: `${savemoneyImg2x} 2x, ${savemoneyImg3x} 3x`
        },
      }
    }
  },
  mounted() {
  }
}
</script>

<style scoped>
img {
  width: 63vw;
  padding: 3%;
  &.mobile {
    width: 100%;
    padding: 0;
  }
}
</style>
  

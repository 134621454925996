<template>
    <v-col cols="12">
        <span class="Header" v-html="name"></span>
    </v-col>
</template>

<script>
export default {
    components: {
    },
    props: {
        name: String
    },
    data () {
        return {}
    },
    computed: {
    },
    methods: {
    }
}
</script>
  
<style scoped>
.Header {
    /* width: 327px; */
    height: 24px;
    margin: 0  0 8px 0;
    font-family: Pretendard;
    font-size: 1.25em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
}
</style>
<template>
    <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="10" class="Rectangle-Back">
            <v-row style="padding: 3%;">
                <v-col cols="4" :class="[type]">
                    <v-row>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-White">
                                <span class="Subtitle"> Process </span> 
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="8" :class="[type]">
                    <v-row>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-Blue">
                                <span class="Content">Library</span>
                            </div>
                        </v-col>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-Blue">
                                <span class="Content">Physics</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="4" :class="[type]">
                    <v-row>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-White">
                                <span class="Subtitle"> Coding </span> 
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="8" :class="[type]">
                    <v-row>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-Blue">
                                <span class="Content">Verilog</span>
                            </div>
                        </v-col>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-Blue">
                                <span class="Content">Embedded Software</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>

                <v-col cols="4" class="system" :class="[type]">
                    <v-row>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-White" style="height: 140px;">
                                <span class="Subtitle"> System </span> 
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="8" class="system" :class="[type]">
                    <v-row>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-Blue">
                                <span class="Content">PMIC</span>
                            </div>
                        </v-col>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-Blue">
                                <span class="Content">System Structure</span>
                            </div>
                        </v-col>
                        <v-col cols=12 style="padding: 5px 12px;">
                            <div class="Rectangle-Blue">
                                <span class="Content">Scenario</span>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="1"></v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        type: String
    }
}
</script>

<style scoped>
.Rectangle-Back {
    height: 390px;
    border-radius: 8px;
    background-color: #113365;
}
.Rectangle-White{
    width: 100%;
    height: 90px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Subtitle {
    width: 148px;
    height: 19px;
    font-family: Pretendard;
    font-size: 1em;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    justify-content: center;
    align-items: center;    

    color: #171717;
    position:absolute;
    z-index: 4;
}
.Rectangle-Blue{
    width: 100%;
    height: 40px;
    border-radius: 4px;
    background-color: #01122a;
    display: flex;
    justify-content: center;
    align-items: center;  
}
.Content{
    width: 318px;
    height: 19px;
    font-family: Pretendard;
    font-size: 1em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    justify-content: center;
    align-items: center;  
    color: #fff;
}

.nocode {
    opacity: 0.2;
    &.system {
        opacity: 1;
        .Rectangle-White {
            background-color: #70eeff;
        }
        .Content {
            color: #70eeff;
        }
    }
}
</style>
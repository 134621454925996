<template>
    <v-row>
        <v-col cols="12">
            <v-row>
                <v-col class="col-12 col-sm-12 col-lg-6">
                    <RequiredTitle :name="conv.title" />
                    <RequiredContents />
                    <RequiredComment :names="conv.comments" />
                </v-col>
                <v-col class="col-12 col-sm-12 col-lg-6">
                    <RequiredTitle :name="nocode.title" />
                    <RequiredContents type="nocode" />
                    <RequiredComment :names="nocode.comments" />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import RequiredTitle from './RequiredTitle.vue';
import RequiredContents from './RequiredContents.vue'
import RequiredComment from './RequiredComment.vue';
export default {
    components: {
        RequiredTitle,
        RequiredContents,
        RequiredComment
    },
    data() {
        return {
            conv: {
                title: "Required Knowledge w/ Conventional Design",
                comments: [
                    "Various field of knowledge required",
                    "Difficult to code",
                    "Difficult to structure"
                ]
            },
            nocode: {
                title: "Required Knowledge w/ No-Code Design",
                comments: [
                    "Only system knowledge is required",
                    "Easy to configure",
                    "Easy to structure"
                ]
            }
        }
    }
}
</script>

<style scoped>
.Title{
    width: 620px;
    height: 29px;
    margin: 0 ;
    font-family: Pretendard;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    display: inline-block;
}
</style>
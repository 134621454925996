<template>
    <v-col cols="12">
        <span class="SubTitle" v-html="name"></span>
    </v-col>
</template>

<script>
export default {
    components: {
    },
    props: {
        name: String
    },
    data () {
        return {}
    },
    computed: {
    },
    methods: {
    }
}
</script>
  
<style scoped>
.SubTitle {
    font-family: 'Pretendard Variable';
    font-size: 1.5625em;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #70eeff;
}
</style>
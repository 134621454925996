<template>
  <v-container style="padding: 0 13% 5% 13%;">
    <v-row>
      <v-col class="col-12">
        <span class="Copyright" v-html="copyright"></span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default{
  components:{
  },
  data() {
    return {
      copyright: `
        ㈜잇다반도체 <br>
        사업자등록번호 : 616-87-02667 ｜ 대표이사 : 전호연 ｜ 주소 : 18478 경기도 화성시 동탄역로 160, 지하 3층 103호 (오산동, 동탄역 롯데캐슬)
        <br><br>
        Copyright © 2022-2024 by ITDA. All Rights Reserved
      `
    }
  }
}
</script>

<style scoped>
.Copyright {
  width: 80%;
  font-family: Pretendard;
  font-size: 0.875em;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5e7da9;
}
</style>
  
<template>
  <v-container class="view" :class="[$vuetify.breakpoint.mobile ? '' : 'desktop']">
    <v-row>
      <v-col class="col-10 col-sm-10 col-lg-12" style="margin:auto">
        <!-- <v-row v-if="!$vuetify.breakpoint.mobile" style="height: 100px;"></v-row> -->
        <Contents
          :split="getValue()"
          :title="title"
          :subTitle="subTitle"
          :contents="contents"
          :image="image" />
        <!-- <v-row style="height: 100px;"></v-row> -->

        <!-- <BoxWrapper /> -->
        <v-container style="display:flex; justify-content: center;">
          <img src="@/assets/main_box8.png" :class="[$vuetify.breakpoint.mobile ? '' : 'desktop-img']">
        </v-container>
        
        <!-- <v-row style="height: 100px;"></v-row> -->
        <InfoTable :title="info.title" :contents="info.contents" />
        <v-row style="height: 100px;"></v-row>
        <Required />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Contents from '../components/Contents.vue';
import InfoTable from '../components/InfoTable.vue';
// import BoxWrapper from '../components/images/main/BoxWrapper.vue'
import Required from '../components/Required.vue';

import img1x from '@/assets/img.png'
import img2x from '@/assets/img@2x.png'
import img3x from '@/assets/img@3x.png'

export default {
  name: 'Main',
  components: {
    Contents,
    InfoTable,
    // BoxWrapper,
    Required
  },
  data() {
    return {
      title: "No-Code <br> SoC Design",
      subTitle: "No-Code Power And Clock System Design",
      contents: [
        "The Power and Clock Canvas facilitates the easy optimization of complex SOC power and clock system designs."
      ],
      image: {
        src: img1x,
        srcset: `${img2x} 2x, ${img3x} 3x`
      },
      info: {
        title: `Less Knowledge,${this.$vuetify.breakpoint.mobile ? "<br>" : " "}But Great System Designs`,
        contents: [
          "Previously, designing power and clock systems was the domain of experts due to the need for extensive field experience.",
          "However, with the Power/Clock Canvas, you can now design a world-class structure based on your system requirements."
        ]
      }
    }
  },
  methods: {
    getValue() {
      return this.$vuetify.breakpoint.mobile ? false : true
    }
  }
}
</script>

<style scoped>
img {
  width: 70vw;
  /* padding: 10%; */
  &.desktop-img {
    padding: 10%;
  }
}
</style>
<template>
    <v-row>
        <v-col cols="1"></v-col>
        <v-col cols="10">
            <v-row
                v-for="(name, idx) in names"
                :key="idx"
            >
                <span class="Comment"> · {{ name }} </span>
            </v-row>
        </v-col>
        <v-col cols="1"></v-col>
        <v-col cols=12 :style="style"></v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        names: Array
    },
    computed: {
        style() {
            return {
                height: this.$vuetify.breakpoint.mobile ? "120px" : "160px"
            }
        }
    }
}
</script>

<style scoped>
.Comment {
    width: 327px;
    height: 20px;
    margin: 8px 0 0 0;
    font-family: Pretendard;
    font-size: 1em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #fff;
    display: inline-block;
}
</style>